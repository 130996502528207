/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-twitter': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.71C13.256.243 11.11.02 9 0 6.89.019 4.744.243 3.568.692 1.386 1.539.667 3.752.518 4.303c0 .003.002-.003-.007.03a8.063 8.063 0 00-.053.224 18.961 18.961 0 00-.456 4.434c-.018 1.366.085 2.888.442 4.4a20.084 20.084 0 00.075.305l.014.052c.177.627.916 2.737 3.035 3.56 1.176.449 3.323.673 5.432.692 2.11-.019 4.256-.224 5.432-.692 2.181-.847 2.9-3.059 3.05-3.611 0-.003-.002.003.006-.03l.022-.085.032-.14c.35-1.528.474-3.069.456-4.433.018-1.378-.087-2.914-.434-4.42l-.035-.152a7.122 7.122 0 00-.023-.091l-.009-.035c-.155-.571-.88-2.759-3.046-3.6z" _fill="#898B9B"/><path pid="1" d="M3 12.66a7.027 7.027 0 0010.778-6.228c.141-.11 1.01-.806 1.222-1.253 0 0-.705.293-1.395.362H13.6l.004-.002c.064-.043.953-.648 1.073-1.369 0 0-.499.266-1.197.499a7.99 7.99 0 01-.361.111 2.465 2.465 0 00-4.202 2.243c-.19-.008-3.09-.17-5.061-2.558 0 0-1.179 1.609.702 3.263 0 0-.571-.023-1.065-.317 0 0-.18 1.949 1.95 2.447 0 0-.42.159-1.089.046 0 0 .374 1.575 2.267 1.722 0 0-1.497 1.351-3.621 1.034z" _fill="#fff"/>'
  }
})
